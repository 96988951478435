<template>
  <b-card-actions title="Filters" action-collapse collapsed>
    <b-card-text>
      <b-row>
        <b-col cols="12" md="6" className="mb-md-0 mb-2">
          <label>Funding Source</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="fundingSourceFilter"
            :options="fundingSourceOptions"
            class="w-100"
            :reduce="(val) => val.value"
            @input="(val) => $emit('update:fundingSourceFilter', val)"
          />
        </b-col>
        <b-col cols="12" md="6" className="mb-md-0 mb-2">
          <label>Status</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="statusFilter"
            :options="statusOptions"
            class="w-100"
            :reduce="(val) => val.value"
            @input="(val) => $emit('update:statusFilter', val)"
          />
        </b-col>
      </b-row>
    </b-card-text>
  </b-card-actions>
</template>

<script>
import { BCard, BCardHeader, BCardBody, BRow, BCol, BCardText } from 'bootstrap-vue';
import vSelect from 'vue-select';
import BCardActions from '@core/components/b-card-actions/BCardActions.vue';

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BCardActions,
    BCardText,
    vSelect,
  },
  props: {
    fundingSourceFilter: {
      type: [String, null],
      default: null,
    },
    statusFilter: {
      type: [String, null],
      default: null,
    },
    fundingSourceOptions: {
      type: Array,
      required: true,
    },
    statusOptions: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
