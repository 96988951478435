<template>
  <div>
    <template-list-add-new
      :is-add-new-template-sidebar-active.sync="isAddNewTemplateSidebarActive"
      :funding-source-options="fundingSourceOptions"
      :status-options="statusOptions"
      @refetch-data="refetchData"
    />

    <!-- Filters -->
    <template-list-filters
      :funding-source-filter.sync="fundingSourceFilter"
      :status-filter.sync="statusFilter"
      :funding-source-options="fundingSourceOptions"
      :status-options="statusOptions"
    />

    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input v-model="searchQuery" class="d-inline-block mr-1" placeholder="Search..." />
              <b-button variant="primary" @click="isAddNewTemplateSidebarActive = true">
                <span class="text-nowrap">Add Template</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refTemplateListTable"
        class="position-relative"
        :items="fetchTemplates"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <!-- Column: Template -->
        <template #cell(name)="data">
          <b-link :to="{ name: 'apps-accounting-receivable-template-edit', params: { id: data.item.id } }">
            <span class="font-weight-bold d-block text-nowrap">
              {{ data.item.name }}
            </span>
          </b-link>
        </template>

        <!-- Column: Visibility -->
        <template #cell(visibility)="data">
          <b-badge pill :variant="`light-primary`" class="text-capitalize">
            {{ title(data.item.visibility) }}
          </b-badge>
        </template>

        <!-- Column: Visibility -->
        <template #cell(fundingSources)="data">
          <b-badge
            pill
            :variant="`light-primary`"
            class="text-capitalize"
            v-for="(fs, index) in data.item.fundingSources"
            :key="index"
          >
            {{ title(fs) }}
          </b-badge>
        </template>

        <template #cell(parent)="data">
          <div class="text-nowrap" v-if="data.item.pathway">
            <b-media vertical-align="center">
              <template #aside>
                <b-avatar
                  size="32"
                  :src="data.item.pathway.avatar"
                  :text="avatarText(data.item.pathway.name)"
                  :variant="`light-primary`"
                  :to="{ name: 'apps-roster-pathway-view', params: { id: data.item.pathway.id } }"
                />
              </template>
              <b-link
                :to="{ name: 'apps-roster-pathway-view', params: { id: data.item.pathway.id } }"
                class="font-weight-bold d-block text-nowrap"
              >
                {{ data.item.pathway.name }}
              </b-link>
              <small class="text-muted"> Pathway </small>
            </b-media>
          </div>
          <div v-else-if="data.item.subject">
            <b-media vertical-align="center">
              <template #aside>
                <b-avatar
                  size="32"
                  :src="data.item.subject.avatar"
                  :text="avatarText(data.item.subject.name)"
                  :variant="`light-primary`"
                  :to="{ name: 'apps-roster-subject-view', params: { id: data.item.subject.id } }"
                />
              </template>
              <b-link
                :to="{ name: 'apps-roster-subject-view', params: { id: data.item.subject.id } }"
                class="font-weight-bold d-block"
                style="inline-size: 300px"
              >
                {{ data.item.subject.name }}
              </b-link>
              <small class="text-muted"> Subject </small>
            </b-media>
          </div>
          <div class="text-nowrap" v-else>No Parent</div>
        </template>

        <!-- Column: default? -->
        <template #cell(default)="data">
          <b-badge pill :variant="`light-${data.item.default ? 'success' : 'danger'}`" class="text-capitalize">
            {{ data.item.default ? 'Yes' : 'No' }}
          </b-badge>
        </template>

        <!-- Column: automatic? -->
        <template #cell(automatic)="data">
          <b-badge pill :variant="`light-${data.item.automatic ? 'success' : 'danger'}`" class="text-capitalize">
            {{ data.item.automatic ? 'Yes' : 'No' }}
          </b-badge>
        </template>

        <!-- Column: Session -->
        <template #cell(createdAt)="data">
          {{ moment(data.item.createdAt).format('L') }}
        </template>

        <!-- Column: Status -->
        <template #cell(status)="data">
          <b-badge pill :variant="`light-primary`" class="text-capitalize">
            {{ title(data.item.status) }}
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">
            <template #button-content>
              <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
            </template>

            <b-dropdown-item :to="{ name: 'apps-accounting-receivable-template-edit', params: { id: data.item.id } }">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>
            <b-dropdown-item @click="archiveTemplate(data.item)">
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Archive</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalTemplates"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
} from 'bootstrap-vue';
import vSelect from 'vue-select';
import store from '@/store';
import { ref, onUnmounted } from '@vue/composition-api';
import { avatarText } from '@core/utils/filter';
import TemplateListFilters from './TemplateListFilters.vue';
import useTemplatesList from './useTemplatesList';
import receivableStoreModule from '../receivableStoreModule';
import TemplateListAddNew from './TemplateListAddNew.vue';
import { title } from '@core/utils/filter';
import moment from 'moment';
import useAccountsList from '@/views/apps/accounting/receivable/account-list/useAccountsList';

export default {
  components: {
    TemplateListFilters,
    TemplateListAddNew,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,

    vSelect,
  },
  methods: {
    editTemplate(data) {},
    archiveTemplate(data) {
      store.dispatch('app-roster/updateTemplate', { id: data.id, templateData: { status: 'archived' } }).then(() => {
        this.refetchData();
      });
    },
    parentTitle(template) {
      if (template.subject) {
        return template.subject.name;
      } else if (template.pathway) {
        return template.pathway.name;
      } else {
        return 'n/a';
      }
    },
  },
  setup() {
    const TEMPLATE_APP_STORE_MODULE_NAME = 'app-accounting-receivable';

    // Register module
    if (!store.hasModule(TEMPLATE_APP_STORE_MODULE_NAME))
      store.registerModule(TEMPLATE_APP_STORE_MODULE_NAME, receivableStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(TEMPLATE_APP_STORE_MODULE_NAME)) store.unregisterModule(TEMPLATE_APP_STORE_MODULE_NAME);
    });

    const isAddNewTemplateSidebarActive = ref(false);

    const {
      fetchTemplates,
      tableColumns,
      perPage,
      currentPage,
      totalTemplates,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refTemplateListTable,
      refetchData,

      // UI
      resolveTemplateRoleVariant,
      resolveTemplateRoleIcon,
      resolveTemplateVisibilityVariant,
      resolveTemplateStatusVariant,

      statusOptions,

      // Extra Filters
      fundingSourceFilter,
      statusFilter,
    } = useTemplatesList();

    const { fundingSourceOptions } = useAccountsList();

    return {
      // Sidebar
      isAddNewTemplateSidebarActive,

      fetchTemplates,
      tableColumns,
      perPage,
      currentPage,
      totalTemplates,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refTemplateListTable,
      refetchData,

      // Filter
      avatarText,
      title,
      moment,

      // UI
      resolveTemplateRoleVariant,
      resolveTemplateRoleIcon,
      resolveTemplateVisibilityVariant,
      resolveTemplateStatusVariant,

      fundingSourceOptions,
      statusOptions,

      // Extra Filters
      fundingSourceFilter,
      statusFilter,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
